export const BlokHorizontalLine = () => import('../../components/storyblok/blok/HorizontalLine.vue' /* webpackChunkName: "components/blok-horizontal-line" */).then(c => wrapFunctional(c.default || c))
export const PageConstructor = () => import('../../components/storyblok/page/Constructor.vue' /* webpackChunkName: "components/page-constructor" */).then(c => wrapFunctional(c.default || c))
export const LayoutConfig = () => import('../../components/storyblok/layout/Config.vue' /* webpackChunkName: "components/layout-config" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/storyblok/layout/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/storyblok/layout/Header.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const UiAppsDownloadLink = () => import('../../components/storyblok/ui/AppsDownloadLink.vue' /* webpackChunkName: "components/ui-apps-download-link" */).then(c => wrapFunctional(c.default || c))
export const UiAppsDownloadQr = () => import('../../components/storyblok/ui/AppsDownloadQr.vue' /* webpackChunkName: "components/ui-apps-download-qr" */).then(c => wrapFunctional(c.default || c))
export const UiHowBuySlide = () => import('../../components/storyblok/ui/HowBuySlide.vue' /* webpackChunkName: "components/ui-how-buy-slide" */).then(c => wrapFunctional(c.default || c))
export const UiInfoDocumentsGroup = () => import('../../components/storyblok/ui/InfoDocumentsGroup.vue' /* webpackChunkName: "components/ui-info-documents-group" */).then(c => wrapFunctional(c.default || c))
export const UiInfoDocumentsItem = () => import('../../components/storyblok/ui/InfoDocumentsItem.vue' /* webpackChunkName: "components/ui-info-documents-item" */).then(c => wrapFunctional(c.default || c))
export const UiLinkApp = () => import('../../components/storyblok/ui/LinkApp.vue' /* webpackChunkName: "components/ui-link-app" */).then(c => wrapFunctional(c.default || c))
export const UiLinkSocial = () => import('../../components/storyblok/ui/LinkSocial.vue' /* webpackChunkName: "components/ui-link-social" */).then(c => wrapFunctional(c.default || c))
export const UiLinkSocialImg = () => import('../../components/storyblok/ui/LinkSocialImg.vue' /* webpackChunkName: "components/ui-link-social-img" */).then(c => wrapFunctional(c.default || c))
export const UiLinkSupport = () => import('../../components/storyblok/ui/LinkSupport.vue' /* webpackChunkName: "components/ui-link-support" */).then(c => wrapFunctional(c.default || c))
export const UiTileImage = () => import('../../components/storyblok/ui/TileImage.vue' /* webpackChunkName: "components/ui-tile-image" */).then(c => wrapFunctional(c.default || c))
export const UiTileText = () => import('../../components/storyblok/ui/TileText.vue' /* webpackChunkName: "components/ui-tile-text" */).then(c => wrapFunctional(c.default || c))
export const BlokAppsDownload = () => import('../../components/storyblok/blok/apps/Download.vue' /* webpackChunkName: "components/blok-apps-download" */).then(c => wrapFunctional(c.default || c))
export const BlokAppsDeepDownload = () => import('../../components/storyblok/blok/apps-deep/Download.vue' /* webpackChunkName: "components/blok-apps-deep-download" */).then(c => wrapFunctional(c.default || c))
export const BlokHomeAbout = () => import('../../components/storyblok/blok/home/About.vue' /* webpackChunkName: "components/blok-home-about" */).then(c => wrapFunctional(c.default || c))
export const BlokHomeCalculator = () => import('../../components/storyblok/blok/home/Calculator.vue' /* webpackChunkName: "components/blok-home-calculator" */).then(c => wrapFunctional(c.default || c))
export const BlokHomeFaq = () => import('../../components/storyblok/blok/home/Faq.vue' /* webpackChunkName: "components/blok-home-faq" */).then(c => wrapFunctional(c.default || c))
export const BlokHomeFeatures = () => import('../../components/storyblok/blok/home/Features.vue' /* webpackChunkName: "components/blok-home-features" */).then(c => wrapFunctional(c.default || c))
export const BlokHomeHero = () => import('../../components/storyblok/blok/home/Hero.vue' /* webpackChunkName: "components/blok-home-hero" */).then(c => wrapFunctional(c.default || c))
export const BlokHomeHowItWorks = () => import('../../components/storyblok/blok/home/HowItWorks.vue' /* webpackChunkName: "components/blok-home-how-it-works" */).then(c => wrapFunctional(c.default || c))
export const BlokHomeHowToBuy = () => import('../../components/storyblok/blok/home/HowToBuy.vue' /* webpackChunkName: "components/blok-home-how-to-buy" */).then(c => wrapFunctional(c.default || c))
export const BlokHomeHowWeHelp = () => import('../../components/storyblok/blok/home/HowWeHelp.vue' /* webpackChunkName: "components/blok-home-how-we-help" */).then(c => wrapFunctional(c.default || c))
export const BlokHomeStories = () => import('../../components/storyblok/blok/home/Stories.vue' /* webpackChunkName: "components/blok-home-stories" */).then(c => wrapFunctional(c.default || c))
export const BlokHomeSupport = () => import('../../components/storyblok/blok/home/Support.vue' /* webpackChunkName: "components/blok-home-support" */).then(c => wrapFunctional(c.default || c))
export const BlokHomeTiles = () => import('../../components/storyblok/blok/home/Tiles.vue' /* webpackChunkName: "components/blok-home-tiles" */).then(c => wrapFunctional(c.default || c))
export const BlokHomeWhatTheyWrite = () => import('../../components/storyblok/blok/home/WhatTheyWrite.vue' /* webpackChunkName: "components/blok-home-what-they-write" */).then(c => wrapFunctional(c.default || c))
export const BlokHomeWhatYouGet = () => import('../../components/storyblok/blok/home/WhatYouGet.vue' /* webpackChunkName: "components/blok-home-what-you-get" */).then(c => wrapFunctional(c.default || c))
export const BlokInfoDocuments = () => import('../../components/storyblok/blok/info/Documents.vue' /* webpackChunkName: "components/blok-info-documents" */).then(c => wrapFunctional(c.default || c))
export const BlokInfoRichtext = () => import('../../components/storyblok/blok/info/Richtext.vue' /* webpackChunkName: "components/blok-info-richtext" */).then(c => wrapFunctional(c.default || c))
export const BaseAccordion = () => import('../../components/base/Accordion.vue' /* webpackChunkName: "components/base-accordion" */).then(c => wrapFunctional(c.default || c))
export const BaseBreadcrumbs = () => import('../../components/base/Breadcrumbs.vue' /* webpackChunkName: "components/base-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const BaseBtn = () => import('../../components/base/Btn.vue' /* webpackChunkName: "components/base-btn" */).then(c => wrapFunctional(c.default || c))
export const BaseChatItem = () => import('../../components/base/ChatItem.vue' /* webpackChunkName: "components/base-chat-item" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../components/base/Checkbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/base/Input.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseInputPlate = () => import('../../components/base/InputPlate.vue' /* webpackChunkName: "components/base-input-plate" */).then(c => wrapFunctional(c.default || c))
export const BaseLinkApp = () => import('../../components/base/LinkApp.vue' /* webpackChunkName: "components/base-link-app" */).then(c => wrapFunctional(c.default || c))
export const BaseLinkWr = () => import('../../components/base/LinkWr.vue' /* webpackChunkName: "components/base-link-wr" */).then(c => wrapFunctional(c.default || c))
export const BaseRichtext = () => import('../../components/base/Richtext.vue' /* webpackChunkName: "components/base-richtext" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/base/Select.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseSpinner = () => import('../../components/base/Spinner.vue' /* webpackChunkName: "components/base-spinner" */).then(c => wrapFunctional(c.default || c))
export const UiDocumentIcon = () => import('../../components/ui/DocumentIcon.vue' /* webpackChunkName: "components/ui-document-icon" */).then(c => wrapFunctional(c.default || c))
export const UiHamburgerBtn = () => import('../../components/ui/HamburgerBtn.vue' /* webpackChunkName: "components/ui-hamburger-btn" */).then(c => wrapFunctional(c.default || c))
export const UiHowToBuySwiper = () => import('../../components/ui/HowToBuySwiper.vue' /* webpackChunkName: "components/ui-how-to-buy-swiper" */).then(c => wrapFunctional(c.default || c))
export const UiLangDropdown = () => import('../../components/ui/LangDropdown.vue' /* webpackChunkName: "components/ui-lang-dropdown" */).then(c => wrapFunctional(c.default || c))
export const RichtextBlocksImage = () => import('../../components/richtext/blocks/Image.vue' /* webpackChunkName: "components/richtext-blocks-image" */).then(c => wrapFunctional(c.default || c))
export const RichtextMarksLink = () => import('../../components/richtext/marks/Link.vue' /* webpackChunkName: "components/richtext-marks-link" */).then(c => wrapFunctional(c.default || c))
export const UiCalculatorCalculation = () => import('../../components/ui/calculator/Calculation.vue' /* webpackChunkName: "components/ui-calculator-calculation" */).then(c => wrapFunctional(c.default || c))
export const UiCalculatorPerYear = () => import('../../components/ui/calculator/PerYear.vue' /* webpackChunkName: "components/ui-calculator-per-year" */).then(c => wrapFunctional(c.default || c))
export const UiCalculatorProducts = () => import('../../components/ui/calculator/Products.vue' /* webpackChunkName: "components/ui-calculator-products" */).then(c => wrapFunctional(c.default || c))
export const UiCalculatorTable = () => import('../../components/ui/calculator/Table.vue' /* webpackChunkName: "components/ui-calculator-table" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
